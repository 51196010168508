import { Outlet, Link } from "react-router-dom";
import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import Skills from "./Skills";


export default function Nav({ }) {
    return (
        <>
        <div className="flex md:flex-row md:justify-between flex-col-reverse">
            
        
        <div className="h-full m-2 md:text-xl  ">
        <div className=" grid text-center text-white md:m-12 m-5" >
         
         <p className="text-center text-white md:m-12"> 
         <SmartphoneIcon  /><span className=" ">
         <a href="tel:+4571461247">+4571461247</a>
             </span></p>
     </div>
        
        <div className=" grid  text-white md:m-12">
            <p className="text-center ">
              <EmailIcon />  <span className=" ">
              < a href="mailto:at309407@gmail.com">  at309407@gmail.com </a>
              </span></p>
            </div>
           

        </div>
       
        <nav>
            <ul className=" navbar tracking-in-expand text-white md:text-2xl text-xl md:p-4  md:m-12" >
                <li className=" m-5 hover:underline hover:text-amber-500  text-center p-2 rounded hover:glow" 
                  data-aos="fade-left"
                  data-aos-offset="200"
                  data-aos-delay="70"
                  data-aos-duration="1000" > 
                    <Link to="/">Home</Link></li>
              
                    <li className=" m-5 hover:underline hover:text-amber-500  text-center p-2 rounded" 
                      data-aos="fade-right"
                      data-aos-offset="200"
                      data-aos-delay="70"
                      data-aos-duration="1000" > 
                        <Link to="/about"> About</Link>
                    </li>
                    <li className=" m-5 hover:underline hover:text-amber-500 text-center p-2 rounded" 
                     data-aos="fade-left"
                     data-aos-offset="200"
                     data-aos-delay="70"
                     data-aos-duration="1000" > 
                        <Link to="/Projects"> Projects</Link>
                    </li>
                {/* <li onClick={() => setActivePage("Projects")}  className={activePage==="Projects"?"active":""} >✨ Projects</li> */}
            </ul>
        </nav>
        </div>
        
        </>
    )}