import React from 'react'
import BasicModal from './BasicModal'
import 'aos/dist/aos.css';
import AOS from 'aos';



AOS.init();

function CarbonC() {
  return (

    <>
   <div className=' absolute top-2 right-6 z-20'>
      <BasicModal/>

    </div>
   
    <div className='grid m-12 md:m-0 md:grid-cols-2 place-content-center md:h-screen'
      data-aos="zoom-in-down"
      data-aos-offset="200"
      data-aos-delay="70"
      data-aos-duration="1000">
      
            <div className=' md:place-content-center md:m-12'>
                
          
      
    <img src="https://www.anugraph.com/files/portfolio5/my_portfolio/public/img/carbon.png" alt="carbon calc image" className='md:w-10/12 '/>
    </div>
  
    <div className=' m-4 md:m-7   text-zinc-200 '>
    <div className='grid place-content-center md:place-content-start'
      data-aos="fade-left"
      data-aos-offset="200"
      data-aos-delay="70"
      data-aos-duration="2000">
       <a href="https://advice-carbon-calculator.netlify.app/" className='md:text-2xl bg-white hover:bg-amber-500  text-black  md:p-2 p-1 rounded m-3'>Visit Website</a> 
    </div>
        {/* <div>
            <img src="https://www.anugraph.com/files/portfolio5/my_portfolio/public/img/Capture.PNG" alt="yarn image"
             className='w-86 h-auto ' />
        </div> */}
        <div className='grid gap-2 leading-7 md:text-[1.2em] tracking-wide '>
            <p>
        We were able to create the carbon Calculator Website prototype for the 
        Advice company as part of the group project.In order to display 
        relevant data for calculating the website's carbon emissions, 
        we downloaded the PageSpeed API and Lighthouse result.
        </p>
        <p>The solution we came up with had two pages. The first page asked for the url of the website you wanted to test and had additional fields to fill out. When the user submitted, it went to the result page, which had a screenshot of the website with certain details. To make it interactive, we used tools like toggle and sliders toggle and sliders so that the user could see 
            the changes and compare them to the data that was originally provided.</p>
            <p>
            We also made the form through which you can store the result page in our database for future
             refrence and share result page with colleague.
            </p>
        </div>
    
    </div>
    </div>
    </>
  )
}

export default CarbonC