import React from 'react'
import BasicModal from './BasicModal'
import 'aos/dist/aos.css';
import AOS from 'aos';



AOS.init();

function Hogwarts() {
  return (

    <>
   <div className=' absolute top-2 right-6 pointer z-20'>
      <BasicModal/>

    </div>
  
    <div className='grid m-12 md:m-0 md:grid-cols-2 place-content-center md:h-screen'
      data-aos="zoom-in-down"
      data-aos-offset="200"
      data-aos-delay="70"
      data-aos-duration="1000">
    <div className=' md:place-content-center md:m-12'>
    <img src="https://www.anugraph.com/files/fullpage%20Fitnessofood.png" alt="Hogwarts image" className='md:w-full h-96 object-cover '/>
  
   </div>
    <div className=' m-2  md:m-12   '>
       
    <div className='grid place-content-center md:place-content-start'
      data-aos="fade-left"
      data-aos-offset="200"
      data-aos-delay="70"
      data-aos-duration="2000">
       <a href="https://fitnessofood.pages.dev/" className='md:text-2xl hover:bg-amber-500  bg-white text-black p-2 rounded m-3'>Visit Website</a> 
    </div>
         {/* <div className='m-3 grid gap-2 md:leading-7 md:text-xl tracking-wide '>
            <p>
            We had to ensure that we spent a significant amount of time planning and structuring our solution because it was a massive assignment with numerous customer requirements, some of which were even contradictory.
Clearing the data, sorting, filtering, pop-up details, blood status, selecting a prefect, and other features were some of the customer requirements.

        </p>
        <p>Our solution is expected to be divided into first, middle, last, and optional nick names despite the fact that the data only contains students' full names.Hacking the system and adding our name to the Student List were two additional features we added.
Our solution was to divide the data into first, middle, last, and optional nicknames. The data only contained students' full names.</p>
        

        </div> */}
          <div className='m-3 grid gap-2 md:leading-7 md:text-xl tracking-wide '>
            <p>
It was our final project for multimedia design degree and we were working in a group of three. We worked with Fitnessofood cafe and restaurant to work on their digital 
platform like social media and website. I was particularly working on the website and the database. We as a team did a 
desk research and took interview and coducted survey in order to finalised our target group and created a low fidility prototype and finally high fidility prototype and I started giving that deisgn a life in ReactJS.

        </p>
     <div className=' md:mt-5 flex md:gap-8 text-orange-300 underline flex-col md:flex-row gap-2'>

<a href="https://www.figma.com/file/pEkj107M2VcTljadwslOpr/Final-Exam-Report-2022?node-id=75%3A31&t=q3IXMbIeaXWlt5S7-1" className='hover:text-orange-200'>  Low fidility prototype
</a>
<a href="https://www.figma.com/file/pEkj107M2VcTljadwslOpr/Final-Exam-Report-2022?node-id=75%3A32&t=Jvz9inCTNzWUwggR-1"  className='hover:text-orange-200'>High fidility prototype</a>
     </div>   


        </div>

    </div>
    </div>
    </>
  )
}

export default Hogwarts