import React from 'react';
import '../index.css';
import Projects from './Projects';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import BasicModal from './BasicModal';
import {  Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import About from './About';
import Footer from './Footer';
import 'animate.css';



AOS.init();

function Main() {
  
  
  return (
    <>
  
   
    <div className='absolute top-2 left-12'>
      <p className='text-5xl font-bold font-serif text-slate-300'>AT.</p>
    </div>
    <div className=' absolute top-2 right-6   '>
      <a href="#" > <BasicModal/></a>

    </div>
    
  

    <div className='absolute right-6 bottom-2 '>
      <div className='grid grid-cols gap-2'>
        
     
      <a href="https://www.linkedin.com/in/anita-tiwari-727930205/" className='hover:shadow-2xl shadow-white '> <LinkedInIcon sx={{ fontSize: 30,
       '&:hover': {
        color: 'purple',
        backgroundColor: 'white',
      }, }} /></a>
        
        <a href="https://github.com/anitatiwari"className='hover:shadow-2xl shadow-white '> <GitHubIcon 
        sx={{ fontSize: 30,
      '&:hover': {
        color: 'purple',
        backgroundColor: 'white',
      }, }} /></a>
<a href="https://www.facebook.com/profile.php?id=100015183063052" className='hover:shadow-2xl shadow-white '> <FacebookIcon 
sx={{ fontSize: 30,
  '&:hover': {
    color: 'purple',
    backgroundColor: 'white',
  }, }} /></a>
        <a href="https://www.instagram.com/anushatiwari9/" className='hover:shadow-2xl shadow-white '> <InstagramIcon sx={{ 
          fontSize: 30,
          '&:hover': {
            color: 'purple',
            backgroundColor: 'white',
          }, }} /></a>
    </div>
    </div>
   
   
      
    <div className='animate grid place-items-center  h-screen '>
    <div className= "  font-['Open_Sans'] z-20 transition delay-150 duration-300 ease-in-out" >
        <div data-aos="zoom-in"
        data-aos-delay="50"
        data-aos-duration="1000">
        <p className=' transition-opacity duration-150  font-bold text-2xl text-gray-400 leading-9' > I'm <span className='text-5xl text-gray-400  leading-2 animate__animated  animate__fadeInLeft'>Anita Tiwari</span> 
         </p>
         </div>
         <div id="container" className=' absolute text-xl font-bold'>
<div className='flex text-slate-300 tracking-wider leading-4 '
data-aos="zoom-in-up"
data-aos-delay="50"
data-aos-duration="1000">
 
  <div>UI/UX/ </div>
  <div className='text-amber-500'>Frontend Designer</div>

 
   

  </div>
</div>
       </div>
     
    </div>
    
    <div className="absolute bottom-12 right-12 left-12 grid place-items-center ">
        
        
<div className='flex  '
lassName='flex '
data-aos="zoom-in-up"
data-aos-delay="50"
data-aos-duration="1000">
{/* <p >Scroll</p> */}
  <div className='animate__animated  animate__repeat-2'>
    
  
  <a href='#about' className='w-10'>
<ArrowDownwardIcon  sx={{ fontSize: 80, opacity:0.4 }}/>
</a>
</div>
</div>

       </div>

       <About />
<Projects   />
<Footer />
<div>

</div>
       </>
  )
}

export default Main