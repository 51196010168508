import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import MenuIcon from '@mui/icons-material/Menu';
import Nav from './Nav'
import { color } from '@mui/system';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

const style = {
 


 
  backgroundColor:"rgba(0,0,0,0.8)",
  marginTop:"100px"

 
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className=''>
     
        <MenuIcon onClick={handleOpen} sx={{fontSize:"40px"}}/>
     
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style} className="float-right w-6/12 place-item-center shadow-xl shadow-zinc-900 rounded-5xl bg-white text-black " 
        
         data-aos="zoom-in-up"
data-aos-delay="50"
data-aos-duration="1000">
         
            <Nav />
          
      
      
        </Box>
      </Modal>
    </div>
  );
}
