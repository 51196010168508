import React from 'react'
import BasicModal from './BasicModal'
import { Link } from 'react-router-dom'

function About() {
  return (
    <>    <div className=' absolute top-2 right-6   '>
    <a href="#" > <BasicModal/></a>

  </div>
    <div id='about' className='Model grid place-items-center h-screen text-xl font-[Proxima Nova] leading-9 m-12 h-fit '
   >
        <div className='container  flex flex-col md:flex-row  justify-between shadow-lg'>
            <div  data-aos="fade-up-right">
                <img src="./profile.jpeg" alt="myprofileimg" className='w-56 md:w-full rounded-lg md:m-8 '/>
            </div>
          <div>
            
        <h1 className='md:text-center p-2 text-3xl md:text-5xl font-serif text-slate-400 m-5' >About me</h1>
            <div className='  rounded-3xl md:m-12 text-[16px] md:text-xl grid lg:grid-cols-2'>
 
      <div>
        
           
<p className='md:max-w-5xl md:m-5'  > 
I'm a passionate web designer with a Multimedia Design degree, focusing on frontend design to create intuitive and engaging user experiences that meet clients' needs. </p>

<p className='md:max-w-5xl md:m-5'  > 
I'm proficient in various technologies such as Bootstrap, Tailwind CSS, WordPress, Scss, Vite, Html CSS, Javascript, and React fundamentals. </p>

</div> 
<div>
<p className='md:max-w-5xl md:m-5 ' >I believe in the power of collaboration and continuously seek to improve my skills to deliver the best possible outcomes.</p>
<p className='md:max-w-5xl md:m-5 ' >
My goal is to help clients achieve their digital goals through effective and visually appealing web design. </p>
<p className='md:max-w-5xl md:m-5 text-amber-200 '> Let's bring your vision to life!</p>
</div>

</div>      
        </div>
    </div>
    </div>
    </>
  )
}

export default About