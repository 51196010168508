import React from 'react'
import BasicModal from './BasicModal'
import AOS from 'aos';
import { Outlet, Link } from "react-router-dom";
import 'aos/dist/aos.css';



AOS.init();
function Projects() {
  return (
    <>
     <div className=' absolute top-2 right-6   '>
      <a href="#" > <BasicModal/></a>

    </div>
    <div className='Model  text-xl font-[Proxima Nova] m-12 '  id='projects'
      >
        <div className='p-4'>
<h1 className='text-[24px] md:text-5xl text-center '>My Projects</h1>
        </div>
    <div class=" m-12 grid md:grid-cols-2 gap-5  md:justify-items-center tracking-wide" >
  <div class="m-2 max-w-md mx-auto  rounded-xl  hover:shadow-lg hover:shadow-black overflow-hidden bg-[#27282e] hover:bg-[#171719] "
   data-aos="zoom-in-down"
   data-aos-offset="200"
   data-aos-delay="70"
   data-aos-duration="1000"
 >
    <div class="w-full justify-between">
      <div class="md:shrink-0  ">
      <Link to="/CarbonC">
        <img class="md:h-50  md:w-80 object-cover  md:ml-8 md:mr-8 md:mt-8 " src="https://www.anugraph.com/files/portfolio5/my_portfolio/public/img/Capture.PNG" alt="project image" />
        </Link>
      </div>
      <div class="pl-8 p-2">
        <div class="uppercase tracking-wide text-sm md:text-2xl font-semibold">Carbon Calculator</div>
        <p class="mt-2 text-slate-200 text-sm ">JavaScript / Scss / CSS / Html / Vite/ Github / Netlify</p>
      </div>
      <div class="pl-8 p-2"  >
      <Link to="/CarbonC">
        <button type="submit" className='shadow-md mb-6 border-2 rounded-full pr-5 pl-5 p-2 hover:bg-gray-600 border-gray-600 '> View Project</button>
        </Link>
      </div>
    </div>
  </div>
  <div class="max-w-md mx-auto  rounded-xl hover:shadow-lg hover:shadow-black overflow-hidden bg-[#27282e] hover:bg-[#171719] "
    data-aos="flip-right"
    data-aos-offset="200"
    data-aos-delay="80"
    data-aos-duration="1000">
    <div class="w-full justify-between">
      <div class="md:shrink-0">
      <Link to="/Hogwarts">
        <img class="md:h-56  md:w-80 object-cover  md:ml-8 md:mr-8 md:mt-8 " src="https://www.anugraph.com/files/Fitnessofood.png" alt="Man looking at item at a store" /></Link>
      </div>
      <div class="pl-8 p-2">
        <div class="uppercase tracking-wide  text-sm md:text-2xl  font-semibold">Fitnesso Food</div>
        <p class="mt-2 text-slate-200 text-sm w-[10rem] md:w-[20rem]">Figma/ Premeire Pro/Reactjs/ Tailwind CSS /Cloudeflare</p>
      </div>
      <div class="pl-8 p-2"  >
      <Link to="/Hogwarts">
        <button type="submit" className='shadow-md mb-6 border-2 rounded-full pr-5 pl-5 p-2 hover:bg-gray-600 border-gray-500'> View Project</button>
        </Link>
        </div>
    </div>
  </div>
 
  <div class="max-w-md mx-auto  rounded-xl hover:shadow-lg hover:shadow-black overflow-hidden bg-[#27282e] hover:bg-[#171719]"
    data-aos="flip-left"
    data-aos-offset="200"
    data-aos-delay="70"
    data-aos-duration="1000">
    <div class="w-full justify-between ">
      <div class="md:shrink-0">
      <Link to="/GarnKbh">
        <img class="md:h-56  md:w-80 object-cover  md:ml-8 md:mr-8 md:mt-8  " src="garn.jpg " alt="Garn Store" />
       
  </Link>
      </div>
      <div class="pl-8 p-2">
        <div class="uppercase tracking-wide text-sm md:text-2xl   font-semibold">Garnkbh</div>
        <p class="mt-2 text-slate-200 text-sm w-[10rem] md:w-[20rem]">JavaScript/Scss/Html/Adobe Premeire Pro/Illustrator/Motion Graphics</p>
      </div>
      <div class="pl-8 p-2"  >
      <Link to="/GarnKbh">
        <button type="submit" className='shadow-md mb-6 border-2 rounded-full pr-5 pl-5 p-2 hover:bg-gray-600 border-gray-600 '> View Project</button>
        </Link>
      </div>
</div>
</div>
<div class="max-w-md mx-auto  rounded-xl  hover:shadow-lg hover:shadow-black overflow-hidden bg-[#27282e] hover:bg-[#171719] "
    data-aos="flip-left"
    data-aos-offset="200"
    data-aos-delay="70"
    data-aos-duration="1000">
    <div class="w-full justify-between ">
      <div class="md:shrink-0">
      <Link to="/Triangle">
        <img class="md:h-56  md:w-80 object-cover  md:ml-8 md:mr-8 md:mt-8  " src="triangle.png " alt="Trinale" />
       
  </Link>
      </div>
      <div class="pl-8 p-2">
        <div class="uppercase tracking-wide text-sm md:text-2xl   font-semibold">Triangle</div>
        <p class="mt-2 text-slate-200 text-sm  ">Angular JS/ Tailwind Css</p>
      </div>
      <div class="pl-8 p-2"  >
      <Link to="/Triangle">
        <button type="submit" className='shadow-md mb-6 border-2 rounded-full pr-5 pl-5 p-2 hover:bg-gray-600 border-gray-600 '> View Project</button>
        </Link>
      </div>
</div>
</div>
</div>
</div>
</>
  )
}

export default Projects