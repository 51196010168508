import React from 'react';
import BasicModal from './BasicModal';
import JavascriptIcon from '@mui/icons-material/Javascript';
import HtmlIcon from '@mui/icons-material/Html';
import CssIcon from '@mui/icons-material/Css';

function Skills() {
  return (

    <div className='grid place-items-center m-12'>
     <p className='text-4xl font-semibold text-white  '>Skills</p> 
<ul className='grid grid-cols-2 gap-2  p-2 text-white '>
 
      <JavascriptIcon 
      sx={{
        fontSize:"5rem",
      
      }}/>
       
      <HtmlIcon  sx={{
         fontSize:"5rem",
      
      }}/>
      <CssIcon sx={{
          fontSize:"5rem",
 
      }}/>
 <div>
  <img src="react.png" alt="" className='w-32' style={{ filter: "grayscale(100%)"}}/>
 </div>
 <div>
  <img src="figma.png" alt="" className='w-28' style={{
 /* Safari 6.0 - 9.0 */
  filter: "grayscale(100%)"
  }}/>
 </div>
     
       </ul></div>
  )
}

export default Skills