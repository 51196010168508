import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';

function Footer() {
  return (
    <div className='  bg-zinc-900 '>
  <div className=' bg- p-12  '>
        <div className='flex flex-col gap-3 w-56' >
            
       
     <h1 className='text-xl text-amber-500'>Keep In Touch</h1>
<div className='flex gap-6'>
    

        <a href="https://www.linkedin.com/in/anita-tiwari-727930205/" className='hover:shadow-2xl shadow-white '> <LinkedInIcon sx={{ fontSize: 30 }} /></a>
        
        <a href="https://github.com/anitatiwari"className='hover:shadow-2xl shadow-white '> <GitHubIcon sx={{ fontSize: 30 }} /></a>
<a href="https://www.facebook.com/profile.php?id=100015183063052" className='hover:shadow-2xl shadow-white '> <FacebookIcon sx={{ fontSize: 30 }} /></a>
        <a href="https://www.instagram.com/anushatiwari9/" className='hover:shadow-2xl shadow-white '> <InstagramIcon sx={{ fontSize: 30 }} /></a>
        </div>
        </div>
        </div>
    </div>
  )
}

export default Footer