import React from 'react'
import BasicModal from './BasicModal'
import 'aos/dist/aos.css';
import AOS from 'aos';



AOS.init();


function Triangle() {
  return (  <>
    <div className=' absolute top-2 right-6 pointer z-20'>
       <BasicModal/>
 
     </div>
    
     <div className='grid m-12 md:m-0 md:grid-cols-2 place-content-center md:h-screen'
       data-aos="zoom-in-down"
       data-aos-offset="200"
       data-aos-delay="70"
       data-aos-duration="1000">
            <div className=' md:place-content-center md:m-12'>

     <img src="triangle2.png" alt="Triangle" className='md:w-full '/>
   </div>
    
     <div className=' m-2  md:m-12   '>
        
     <div className='grid place-content-center md:place-content-start'
           data-aos="fade-left"
           data-aos-offset="200"
           data-aos-delay="70"
           data-aos-duration="2000">
        <a href="https://trianglecalcv2.netlify.app/" className='md:text-2xl hover:bg-amber-500  bg-white text-black p-2 rounded'>Visit Website</a> 
     </div>
     <div className='m-3 grid gap-2 md:leading-7 md:text-xl tracking-wide '>
             <p>
             I created this private project to learn Angular js.
I developed a website on which we can specify three lengths for each side of a triangle and it will determine whether the sides are scalar, isosecels, or equilateral.
         </p>
         <p>
         I styled the website with tailwind css.
            </p>

     </div>
     </div>
     </div>
     </>
   )
 }
 

export default Triangle