import React from 'react'
import BasicModal from './BasicModal'
import 'aos/dist/aos.css';
import AOS from 'aos';



AOS.init();

function GarnKbh() {
  return (  <>
    <div className=' absolute top-2 right-6 pointer z-20'>
       <BasicModal/>
 
     </div>
    
     <div className='grid m-12 md:m-0 md:grid-cols-2 place-content-center md:h-screen'
       data-aos="zoom-in-down"
       data-aos-offset="200"
       data-aos-delay="70"
       data-aos-duration="1000">
                    <div className=' md:place-content-center md:m-12'>

     <img src="garn.jpg" alt="yarn image" className=''/>
   </div>
    
     <div className=' m-2  md:m-12   '>
         {/* <div>
             <img src="https://www.anugraph.com/files/portfolio5/my_portfolio/public/img/Capture.PNG" alt="yarn image"
              className='w-86 h-auto ' />
         </div> */}
    <div className='grid place-content-center md:place-content-start'
      data-aos="fade-left"
      data-aos-offset="200"
      data-aos-delay="70"
      data-aos-duration="2000">
       <a href="https://anitatiwari.github.io/garnkbh/home.html" className='md:text-2xl bg-white hover:bg-amber-500  text-black p-2 rounded m-3'>Visit Website</a> 
    </div>

    <div className='m-3 grid gap-2 md:leading-7 md:text-xl tracking-wide '>
            <p>
         This was a team effort to create social media content for a new company called GarnKBH.We were able to develop the company's website and some content for social media after conducting research and interviews with the target audience.We have produced video and motion graphics content for YouTube and Instagram.
SEO was incorporated into this project to ensure that our solution reached the intended audience.

         </p>
       
             <p>
             <span className='text-yellow-300'>Problem statement:</span> How can a digital solution that can raise sales and awareness for GARNkbh be developed?
             </p>
        
         </div>
     </div>
     </div>
     </>
   )
 }

export default GarnKbh