import logo from './logo.svg';
import Main from './Component/Main'
import Skill from './Component/Skills';
import Nav from './Component/Nav';
import About from './Component/About';
import Projects from './Component/Projects';
import Hogwarts from './Component/Hogwarts';
import CarbonC from './Component/CarbonC';
import GarnKbh from './Component/GarnKbh';
import Triangle from './Component/Triangle';
import { BrowserRouter, BrowserRouter as Router, Route, Routes } from 'react-router-dom';



function App() {
  return (
    <>
    <BrowserRouter>

   
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/Skills" element={<Skill />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/Projects" element={<Projects />} />
          <Route exact path="/Hogwarts" element={<Hogwarts />} />
          <Route exact path="/CarbonC" element={<CarbonC />} />
          <Route exact path="/GarnKbh" element={<GarnKbh />} />
          <Route exact path="/Triangle" element={<Triangle />} />






          {/* <Route exact path="/Nav" element={<Nav />}/> */}
        </Routes>
        </BrowserRouter>
       
    

</>
//     <div id='proj'>
      
   
//     <div className="fixed top-0 left-0 App font-mono  h-[100vh]  shadow-sm shadow-slate-600 ">
 
//      <h1 className="text-2xl text-left p-12  ">
//  Anita
//   </h1>


//   <div className='list-none float-left p-12  text-left '>
   
//   <li className='mr-2 p-4'> <a href="#">Home</a></li>
//   <li className='mr-2 p-4'> <a href="">My Skills</a></li>
//   <li className='mr-2 p-4'> <a href="#projects">My Projects</a></li>

//   </div>
//   </div>
//   <Model></Model>
//   <Myskills />

//   </div>
  );
}

export default App;
